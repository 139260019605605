import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../..';
import { ListGroup, ListGroupItem } from 'reactstrap';

const FeedPage = observer(() => {
  const store = useRootStore();

  useEffect(() => {
    store.gamesStore.loadFeedAsync()
  }, [store.gamesStore]);

  return (
    <ListGroup style={{ margin: '25px', }}>
      {store.gamesStore.feed.map(item => (
        <ListGroupItem key={`${item.username}-${item.game.id}`} style={{ backgroundColor: '#1F1F1F', borderColor: '#858585', }}>
          <img src={item.game.imageUrl} alt={item.game.name} style={{float: 'left', width: '150px', marginRight: '10px', }} />
          <span style={{fontWeight: 'bold', }}>{item.username}</span> has {item.status.toString().toLowerCase()} <span style={{fontWeight: 'bold', }}>{item.game.name}</span> at {new Date(item.actionDate).toLocaleString()} {item.rating && (
            <span>and rated it <span style={{fontWeight: 'bold', }}>{item.rating}/5</span></span>
          )}
        </ListGroupItem>
      ))}
    </ListGroup>
  );
});

export default FeedPage;
