import { observer } from 'mobx-react-lite';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useRootStore } from '..';
import BrowsePage from './browse/BrowsePage';
import Layout from './common/components/Layout';
import HomePage from './home/HomePage';
import LoginPage from './login/LoginPage';
import FeedPage from './feed/FeedPage';

export const Routes = observer(() => {
    const store = useRootStore();
    return (
        <Switch>
            <RootRoute path="/" exact={true} isConnected={store.userStore.connected} />
            <PublicRoute path="/login" exact={false} component={LoginPage} isConnected={store.userStore.connected} />
            <PrivateRoute path="/home" exact={true} component={HomePage} isConnected={store.userStore.connected} />
            <PrivateRoute path="/browse" exact={true} component={BrowsePage} isConnected={store.userStore.connected} />
            <PrivateRoute path="/feed" exact={true} component={FeedPage} isConnected={store.userStore.connected} />
        </Switch>
    )
});

const PrivateRoute = ({ component: Component, isConnected, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={props =>
                isConnected ? (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};

const PublicRoute = ({ component: Component, isConnected, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={props => {
                // @ts-ignore
                const { from } = props.location.state || { from: { pathname: '/' } };
                if (isConnected) {
                    return (
                        <Redirect
                            to={from}
                        />
                    );
                } else {
                    return (
                        <Component {...props} />
                    );
                }
            }}
        />
    );
};

const RootRoute = ({isConnected, ...rest }: any) => {
    return (
        <Route
            {...rest}
            render={props =>
                isConnected ? (
                    <Redirect
                        to={{
                            pathname: '/home',
                            state: { from: props.location }
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        />
    );
};
