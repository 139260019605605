import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../..';
import { Game, completeGameAsync, GameStatus } from '../../../services/gamesServices';
import { Button, Label, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { MdOutlineDone } from 'react-icons/md'

interface Props {
    game: Game|null;
    toggle: () => void;
    isOpen: boolean;
}

const RatingElement = observer((props: Props) => {
    const store = useRootStore();
    const [rating, setRating] = useState(0)

    const markGameAsComplete = () => {
      if (props.game !== null) {
        return completeGameAsync(store.userStore.name, props.game.id, rating).then(() => {
          store.gamesStore.updateGameCollection(props.game!.id, 'completed');
          store.gamesStore.updateGameStatus(props.game!.id, GameStatus.Completed);
          props.toggle();
        });
      }
    }

    return (
      <div>
        <Modal isOpen={props.isOpen} toggle={props.toggle} style={{color: 'black'}}>
          <ModalHeader toggle={props.toggle}>Mark {props.game?.name} as complete</ModalHeader>
          <ModalBody>
            <FormGroup>
              <img src={props.game?.imageUrl} alt={props.game?.name} style={{ width: '100px', float: 'left', margin: '5px' }} />
              <p>
                {props.game?.description}
              </p>
              <Label for="rating">Rating</Label>
              <Input type="select" name="rating" value={rating} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRating(Number(event.target.value))} id="rating">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={markGameAsComplete}><MdOutlineDone /> Complete</Button>
            <Button color="secondary" onClick={props.toggle}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
  )
});

export default RatingElement;
