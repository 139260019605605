import { makeAutoObservable } from "mobx";
import { makePersistable } from 'mobx-persist-store';
import { RootStore } from "../rootStore";

export class UserStore {
    rootStore: RootStore;
    name = '';
    connected = false;
    listMode = true;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this, { rootStore: false });
        makePersistable(this, {
            name: 'UserStore',
            properties: [
                'connected',
                'name',
                'listMode',
            ],
            storage: window.localStorage
        });
    }

    toggleListView = () => {
        this.listMode = !this.listMode;
    }

    signInUser = (userName: string) => {
        this.name = userName;
        this.connected = true;
        this.listMode = true;
    }

    signOutUser = () => {
        this.setDefaultState();
    }

    private setDefaultState = () => {
        this.name = '';
        this.connected = false;
        this.listMode = true;
    }
}
