import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../..';
import { Game } from '../../services/gamesServices';
import GameCollection from '../common/components/GameCollection';
import GameCarousel from '../common/components/GameCarousel';
import RatingElement from '../common/components/RatingElement';

const HomePage = observer(() => {
  const store = useRootStore();

  const [isCompletingGame, setIsCompletingGame] = useState(false);
  const [game, setGame] = useState<Game|null>(null);

  useEffect(() => {
    store.gamesStore.loadGamesAsync()
    store.gamesStore.loadAllGamesAsync()
  }, [store.gamesStore]);

  const toggle = () => {
    setIsCompletingGame(!isCompletingGame);
  }
  const launchCompleteModal = (game: Game) => {
    setIsCompletingGame(true);
    setGame(game);
  }

  return (
    <div>
      <RatingElement toggle={toggle} isOpen={isCompletingGame} game={game} />
      <GameCarousel games={store.gamesStore.games.slice(5, 9)}/>
      <GameCollection name="Started" launchCompleteModal={launchCompleteModal} games={store.gamesStore.startedGames.slice(0, 20).map(f => f.game)} username={store.userStore.name} />
      <GameCollection name="Backlog" launchCompleteModal={launchCompleteModal} games={store.gamesStore.backlogGames.slice(0, 20).map(f => f.game)} username={store.userStore.name} />
      <GameCollection name="Completed" launchCompleteModal={launchCompleteModal} games={store.gamesStore.completedGames.slice(0, 20).map(f => f.game)} username={store.userStore.name} />
    </div>
  );
});

export default HomePage;
