import { action, makeAutoObservable } from "mobx";
import { UserGameItem, FeedItem, getUserGamesAsync, getFeedAsync, Game, GameStatus, getAllGamesAsync } from "../../services/gamesServices";
import { RootStore } from "../rootStore";

export class GamesStore {
    rootStore: RootStore;
    games: Game[] = [];
    lastRefreshedAt: number = 0;

    completedGames: UserGameItem[] = [];
    startedGames: UserGameItem[] = [];
    backlogGames: UserGameItem[] = [];
    feed: FeedItem[] = [];
    statusGames: {[key: string]: GameStatus} = {};

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, { rootStore: false });
        this.rootStore = rootStore;
    }

    get platforms() {
        return [...new Set(this.games.map(g => g.platforms).flat())];
    }

    loadGamesAsync = () => {
        return getUserGamesAsync(this.rootStore.userStore.name)
            .then(action('setCurrentUserState', response => {
                    let temporaryGamesList: {[key: string]: GameStatus} = {};
                    response.data.completed.forEach(game => {
                        temporaryGamesList[game.game.id] = GameStatus.Completed;
                    });
                    response.data.started.forEach(game => {
                        temporaryGamesList[game.game.id] = GameStatus.Started;
                    });
                    response.data.backlog.forEach(game => {
                        temporaryGamesList[game.game.id] = GameStatus.Backlog;
                    });
                    this.completedGames = response.data.completed;
                    this.startedGames = response.data.started;
                    this.backlogGames = response.data.backlog;
                    this.statusGames = temporaryGamesList;
                })
            );
    }
    loadFeedAsync = () => {
        return getFeedAsync()
            .then(action('saveFeed', response => {
                    this.feed = response.data.actions.sort((a, b) => new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime());
                })
            );
    }

    updateGameCollection = (gameId: string, toCollection: 'backlog' | 'started' | 'completed' ) => {
        let game = this.backlogGames.find(b => b.game.id === gameId);
        if (game !== undefined) {
            this.backlogGames = this.backlogGames.filter(b => b.game.id !== gameId);
        } else {
            game = this.startedGames.find(b => b.game.id === gameId);
            this.startedGames = this.startedGames.filter(b => b.game.id !== gameId);
        }

        if (game !== undefined) {
            if (toCollection === 'started') {
                this.startedGames.unshift(game);
            } else if (toCollection === 'backlog') {
                this.backlogGames.unshift(game);
            } else {
                this.completedGames.unshift(game);
            }
        }
    }

    updateGameStatus = (gameId: string, status: GameStatus) => {
        this.statusGames[gameId] = status;
    }

    loadAllGamesAsync = () => {
        if (Date.now() - this.lastRefreshedAt > 60 * 60 * 1000) {
            return getAllGamesAsync()
                .then(action('saveGamesData', (response) => {
                    this.games = response.data.games;
                    this.lastRefreshedAt = Date.now();
                })
            );
        }
    }
}
