import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../..';
import { Game } from '../../services/gamesServices';
import BrowseCollection from '../common/components/BrowseCollection';
import ListCollection from '../common/components/ListCollection';
import RatingElement from '../common/components/RatingElement';
import { Button, ButtonGroup, ButtonToolbar, Col, Input, InputGroup, Row } from 'reactstrap';
import { FaList, FaTable, FaXbox, FaDesktop } from 'react-icons/fa';

const BrowsePage = observer(() => {
  const store = useRootStore();
  const [searchText, setSearchText] = useState('');
  const [selectedPlatforms, setSelectedPlatforms] = useState<{[key: string]: boolean}>({});

  const togglePlatformFilter = (platform: string) => {
    setSelectedPlatforms({
      ...selectedPlatforms,
      [platform]: !selectedPlatforms[platform]
    })
  }

  const hasAnyPlatformFilter = () => {
    return Object.keys(selectedPlatforms).some(key => selectedPlatforms[key])
  }

  useEffect(() => {
    store.gamesStore.loadGamesAsync()
    store.gamesStore.loadAllGamesAsync()
  }, [store.gamesStore]);

  const filteredGames = store.gamesStore.games
    .filter(g => g.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
    .filter(g => hasAnyPlatformFilter() ? g.platforms.some(p => selectedPlatforms[p]) : true);

  const [isCompletingGame, setIsCompletingGame] = useState(false);
  const [game, setGame] = useState<Game|null>(null);

  const toggle = () => {
    setIsCompletingGame(!isCompletingGame);
  }
  const launchCompleteModal = (game: Game) => {
    setIsCompletingGame(true);
    setGame(game);
  }

  let buttonText;
  let collection;
  if (store.userStore.listMode) {
    buttonText = <span><FaTable /> Collection view</span>
    collection = <ListCollection launchCompleteModal={launchCompleteModal} games={filteredGames} gamesStatus={store.gamesStore.statusGames} username={store.userStore.name}/>
  }
  else {
    buttonText = <span><FaList /> List view</span>
    collection = <BrowseCollection launchCompleteModal={launchCompleteModal} games={filteredGames} gamesStatus={store.gamesStore.statusGames} username={store.userStore.name}/>
  }
  let platforms : {[key: string]: any} = {
    'xsx': (<span><FaXbox /> Xbox Series X|S</span>),
    'xboxOne': (<span><FaXbox /> Xbox One</span>),
    'pc': (<span><FaDesktop /> PC</span>),
  }

  return (
    <div>
      <RatingElement game={game} isOpen={isCompletingGame} toggle={toggle} />
      <Row>
        <Col>
          <p className="lead">
            &nbsp;{filteredGames.length} games
            {hasAnyPlatformFilter() ? ` (Filtered on platforms: ${Object.keys(selectedPlatforms).filter(key => selectedPlatforms[key]).join()})` : ''}
          </p>
        </Col>
        <Col>
          <Row>
            <Col>
              <InputGroup>
                <Input
                  placeholder="Search"
                  value={searchText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value)}
                />
              </InputGroup>
              </Col>
          </Row>
          <Row>
            <Col>
              <ButtonToolbar className="float-right">
                <ButtonGroup style={{marginRight: '10px'}}>
                  {store.gamesStore.platforms.map(p => (
                    <Button key={p} onClick={() => togglePlatformFilter(p)}>
                      {platforms[p]}
                    </Button>
                  ))}
                </ButtonGroup>
                <Button onClick={store.userStore.toggleListView}>{buttonText}</Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{marginTop: '10px'}}>
        {collection}
      </div>
    </div>
  );
});

export default BrowsePage;
