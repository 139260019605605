import React, { createContext, useContext } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RootStore } from './stores/rootStore';
import { BrowserRouter } from 'react-router-dom';
import { Routes } from './app/Routes';

// Init our RootStore singleton
const rootStore = new RootStore();

// Create the RootStore context
const RootStoreContext = createContext<RootStore>(rootStore);

export const useRootStore = () => {
  const context = useContext(RootStoreContext);
  if (context === undefined) {
    throw new Error("ca va pas");
  }

  return context;
}

ReactDOM.render(
  <React.StrictMode>
    <RootStoreContext.Provider value={rootStore}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </RootStoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
