import { observer } from 'mobx-react-lite';
import { Game, startGameAsync } from '../../../services/gamesServices';
import { Card, CardBody, CardImg, CardTitle, ListGroup, ListGroupItem, Button, ButtonGroup, ButtonToolbar, Badge } from 'reactstrap';
import { BsFillPlayFill } from 'react-icons/bs'
import { MdOutlineDone } from 'react-icons/md'
import { FaStar } from 'react-icons/fa';
import { useRootStore } from '../../..';

interface Props {
    name: string;
    games: Game[];
    username: string;
    launchCompleteModal: (arg: Game) => void;
}

const GameCollection = observer((props: Props) => {
    const store = useRootStore();
    const completeGame = (game: Game) => {
      props.launchCompleteModal(game);
    }
    const startGame = (gameId: string) => {
      startGameAsync(props.username, gameId).then(() => store.gamesStore.updateGameCollection(gameId, 'started'));
    }

    return (
    <div style={{marginTop: '0.5rem'}}>
        <h2>{props.name}</h2>
        <ListGroup horizontal={true} style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(100, 1fr)',
            overflow: 'auto',
        }}>
            {props.games.map((game) => (
                <ListGroupItem key={game.id} tag={Card} style={{width: '150px', textAlign: 'center'}}>
                    <CardImg top width="100%" src={game.imageUrl} alt="Card image cap" />
                    <CardBody style={{ paddingTop: '0px' }}>
                        {props.name === 'Started' && (
                            <ButtonToolbar style={{ position: 'absolute', top: '136px', left: '58px', display: 'none', textAlign: 'center', paddingTop: '5px' }}>
                                <ButtonGroup>
                                    <Button color="light" size="sm" onClick={() => completeGame(game)}><MdOutlineDone /></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        )}
                        {props.name === 'Backlog' && (
                            <ButtonToolbar style={{ position: 'absolute', top: '136px', display: 'none', textAlign: 'center', paddingTop: '5px' }}>
                                <ButtonGroup>
                                    <Button color="secondary" size="sm" onClick={() => startGame(game.id)}><BsFillPlayFill /></Button>
                                    <Button color="light" size="sm" onClick={() => completeGame(game)}><MdOutlineDone /></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        )}
                            <CardTitle tag="h5" style={{fontSize: '0.9rem'}}>{game.name}</CardTitle>
                            {game.rating !== null && (
                                <Badge color='warning' style={{position: 'absolute', top: '12px', left:'20px'}}>
                                    <FaStar /> {game.rating}
                                </Badge>
                            )}
                        </CardBody>
                </ListGroupItem>
            ))}
        </ListGroup>
    </div>
)
});

export default GameCollection;
