import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../..';
import { Game, GameStatus, startGameAsync, addToBacklogGameAsync } from '../../../services/gamesServices';
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, CardBody, CardImg, CardTitle, ListGroup, ListGroupItem } from 'reactstrap';
import { BsFillPlayFill } from 'react-icons/bs'
import { MdOutlineDone } from 'react-icons/md'
import { BiLibrary } from 'react-icons/bi'
import { FaStar } from 'react-icons/fa';

interface Props {
    games: Game[];
    gamesStatus: {[key: string]: GameStatus};
    username: string;
    launchCompleteModal: (arg: Game) => void;
}

const GameCollection = observer((props: Props) => {
    const store = useRootStore();

    const completeGame = (game: Game) => {
        props.launchCompleteModal(game);
    }
    const startGame = (gameId: string) => {
      startGameAsync(props.username, gameId).then(() => store.gamesStore.updateGameStatus(gameId, GameStatus.Started));
    }
    const backlogGame = (gameId: string) => {
      addToBacklogGameAsync(props.username, gameId).then(() => store.gamesStore.updateGameStatus(gameId, GameStatus.Backlog));
    }

    const createButtons = (game: Game) => {
        switch (props.gamesStatus[game.id]) {
            case GameStatus.Completed:
                return (<span></span>);
            case GameStatus.Backlog:
                return (
                    <ButtonToolbar style={{ position: 'absolute', top: '200px', left: '68px', display: 'none', textAlign: 'center', paddingTop: '5px' }}>
                        <ButtonGroup>
                            <Button color="secondary" size="sm" onClick={() => startGame(game.id)}><BsFillPlayFill /></Button>
                            <Button color="light" size="sm" onClick={() => completeGame(game)}><MdOutlineDone /></Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                );
            case GameStatus.Started:
                return (
                    <ButtonToolbar style={{ position: 'absolute', top: '200px', left: '84px', display: 'none', textAlign: 'center', paddingTop: '5px' }}>
                        <ButtonGroup>
                            <Button color="light" size="sm" onClick={() => completeGame(game)}><MdOutlineDone /></Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                );
            default:
                return (
                    <ButtonToolbar style={{ position: 'absolute', top: '200px', left: '52px', display: 'none', textAlign: 'center', paddingTop: '5px' }}>
                        <ButtonGroup>
                            <Button color="secondary" size="sm" onClick={() => startGame(game.id)}><BsFillPlayFill /></Button>
                            <Button color="light" size="sm" onClick={() => completeGame(game)}><MdOutlineDone /></Button>
                            <Button color="info" size="sm" onClick={() => backlogGame(game.id)}><BiLibrary /></Button>
                        </ButtonGroup>
                    </ButtonToolbar>
                );
        }
    }

    return (
    <div style={{overflow: 'auto'}}>
        <ListGroup horizontal={true} style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(8, 1fr)',
            gridGap: '1em',
            overflow: 'auto',
        }}>
            {props.games.map((game) => (
                <ListGroupItem key={game.id} tag={Card} style={{width: '200px'}}>
                    <CardImg top width="100%" src={game.imageUrl} alt="Card image cap" />
                        <CardBody style={{paddingTop: '0px'}}>
                            {createButtons(game)}
                            <CardTitle tag="h5">{game.name}</CardTitle>
                            {game.rating !== null && (
                                <Badge color='warning' style={{position: 'absolute', top: '12px', left:'20px'}}>
                                    <FaStar /> {game.rating}
                                </Badge>
                            )}
                        </CardBody>
                </ListGroupItem>
            ))}
        </ListGroup>
    </div>
    )
});

export default GameCollection;
