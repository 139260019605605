import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../..';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

const LoginPage = observer(() => {
  const store = useRootStore();
  const [username, setUsername] = useState('')

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    store.userStore.signInUser(username);
  }

  return (
    <div className="text-center">
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="username">Username</Label>
          <Input
            type="text"
            name="username"
            id="username"
            placeholder="Username"
            value={username}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUsername(event.target.value)}
            required={true}
          />
        </FormGroup>
        <Button type="submit">Sign in</Button>
      </Form>
    </div>
  );
});

export default LoginPage;
