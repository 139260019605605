import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../..';
import { Game, GameStatus, startGameAsync, addToBacklogGameAsync } from '../../../services/gamesServices';
import { Table, Button } from 'reactstrap';
import { BsFillPlayFill } from 'react-icons/bs'
import { MdOutlineDone } from 'react-icons/md'
import { BiLibrary } from 'react-icons/bi'
import { FaDesktop, FaGamepad, FaXbox } from 'react-icons/fa';

interface Props {
    games: Game[];
    gamesStatus: {[key: string]: GameStatus};
    username: string;
    launchCompleteModal: (arg: Game) => void;
}

const ListCollection = observer((props: Props) => {
    const store = useRootStore();

    const completeGame = (game: Game) => {
        props.launchCompleteModal(game);
    }
    const startGame = (gameId: string) => {
      startGameAsync(props.username, gameId).then(() => store.gamesStore.updateGameStatus(gameId, GameStatus.Started));
    }
    const backlogGame = (gameId: string) => {
      addToBacklogGameAsync(props.username, gameId).then(() => store.gamesStore.updateGameStatus(gameId, GameStatus.Backlog));
    }

    const getPlatformIcon = (platform: string) => {
        switch (platform) {
            case 'xsx':
            case 'xboxOne':
                return <>&nbsp;<FaXbox /></>;
            case 'pc':
                return <>&nbsp;<FaDesktop /></>;
            default:
                return <>&nbsp;<FaGamepad /></>;
        }
    }

    return (
    <Table style={{color: '#fff'}}>
        <thead>
            <tr>
                <th>Name</th>
                <th>Rating</th>
                <th>Platforms</th>
                <th>Duration</th>
                <th>Start</th>
                <th>Complete</th>
                <th>Backlog</th>
            </tr>
        </thead>
        <tbody>{props.games.map((game) => (
            <tr key={game.id}>
                <td>{game.name}</td>
                <td>{game.rating}</td>
                <td>{game.platforms.map(p => getPlatformIcon(p))}</td>
                <td>{game.durationInMinutes > 0 ? `${game.durationInMinutes / 60}h` : null}</td>
                <td>
                    {props.gamesStatus[game.id] !== GameStatus.Completed && props.gamesStatus[game.id] !== GameStatus.Started && (
                        <Button color="success" onClick={() => startGame(game.id)}><BsFillPlayFill /></Button>
                    )}
                </td>
                <td>
                    {props.gamesStatus[game.id] !== GameStatus.Completed && (
                        <Button color="light" onClick={() => completeGame(game)}><MdOutlineDone /></Button>
                    )}
                </td>
                <td>
                    {props.gamesStatus[game.id] === undefined && (
                        <Button color="secondary" onClick={() => backlogGame(game.id)}><BiLibrary /></Button>
                    )}
                </td>
            </tr>
            ))}
        </tbody>
    </Table>
)
});

export default ListCollection;
