import httpClient from '../clients/goodPlayHttpCommon';

export const getUserGamesAsync = (username: string) => {
    return httpClient.get<UserGamesResponse>(`/users/${username}/games`);
}
export const getFeedAsync = () => {
    return httpClient.get<UserFeedResponse>(`/feed`);
}
// TODO: add review
export const completeGameAsync = (username: string, gameId: string, rating?: number) => {
    return httpClient.post(`/users/${username}/games/${gameId}/complete`, {
        rating: rating,
    });
}
export const startGameAsync = (username: string, gameId: string) => {
    return httpClient.post(`/users/${username}/games/${gameId}/start`, {});
}
export const addToBacklogGameAsync = (username: string, gameId: string) => {
    return httpClient.post(`/users/${username}/games/${gameId}/backlog`, {});
}

export const getAllGamesAsync = () => {
    return httpClient.get<AllGamesResponse>(`/games`);
}

export interface Game {
    name: string;
    categories?: any;
    durationInMinutes: number;
    imageUrl: string;
    platforms: string[];
    link?: any;
    description: string;
    id: string;
    addedOn: Date;
    rating?: number;
}

export interface UserGameItem {
    game: Game;
    startedAt?: Date;
    completedAt: Date;
    rating?: number;
    timeToFinishInMinutes?: number;
    review?: number;
}
export interface FeedItem {
    game: Game;
    actionDate: string;
    status: GameStatus;
    username: string;
    rating?: number;
}
export enum GameStatus {
    Completed,
    Started,
    Backlog,
}
export interface UserGamesResponse {
    completed: UserGameItem[];
    started: UserGameItem[];
    backlog: UserGameItem[];
}
export interface UserFeedResponse {
    actions: FeedItem[];
}


export interface AllGamesResponse {
    games: Game[];
}
