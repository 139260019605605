import { GamesStore } from "./games/gamesStore";
import { UserStore } from "./user/userStore";

export class RootStore {
    userStore: UserStore;
    gamesStore: GamesStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.gamesStore = new GamesStore(this);
    }
}
