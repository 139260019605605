import { observer } from "mobx-react-lite";
import { FaGamepad, FaPowerOff } from "react-icons/fa";
import { Navbar, NavbarBrand, Nav, NavItem, NavLink, Button } from "reactstrap";
import { NavLink as NavLinkRouter } from 'react-router-dom';
import { useRootStore } from "../../..";

interface Props {
    children: JSX.Element
}

const Layout = observer((props: Props) => {
    const store = useRootStore();
    return (
        <div>
            <Navbar dark expand="md" fixed="top">
                <NavbarBrand href="/"><FaGamepad />&nbsp;GoodPlay</NavbarBrand>
                <Nav className="mr-auto" navbar={true}>
                    <NavItem>
                        <NavLink
                            tag={NavLinkRouter}
                            to="/home"
                            activeClassName={`active`}
                        >
                            Home
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={NavLinkRouter}
                            to="/browse"
                            activeClassName={`active`}
                        >
                            Browse
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={NavLinkRouter}
                            to="/feed"
                            activeClassName={`active`}
                        >
                            Feed
                        </NavLink>
                    </NavItem>
                </Nav>
                <Nav navbar={true} className="my-sm-0">
                    <NavItem>
                        <Button color="danger" onClick={store.userStore.signOutUser}><FaPowerOff /> Logout</Button>
                    </NavItem>
                </Nav>
            </Navbar>
            {props.children}
        </div>
    );
});

export default Layout;
